//Main
import "./App.css";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";

//Additional Files
import SelectionScreen from "./Components/SelectionScreen";
import MainUI from "./Components/MainUI";
import { GlobalContextProvider } from "./Components/GlobalContextManager";
import ErrorPage from "./Components/ErrorPage";
import MobileLandscape from "./Components/MobileLandscape";

function App() {
    const [renderSelScrn, setRenderSelScrn] = useState(true);
    return (
        <div className="App">
            <MobileLandscape/>
            <GlobalContextProvider>
                <Routes>
                    <Route path="/GX4" element={<MainUI />} />
                    <Route path="/GX4XL" element={<MainUI />} />
                    <Route exact path="/" element={<SelectionScreen renderSelScrn={(bool)=>{setRenderSelScrn(bool)}} />} />
                    <Route path="/*" element={<ErrorPage/>} />
                </Routes>
            </GlobalContextProvider>
        </div>
    );
}

export default App;
