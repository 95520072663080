//Main
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";

//Additional Files
import "./AnnotationHandler.css";
import { useGlobalContext, useGlobalContextUpdate } from "../GlobalContextManager";

//MUI/Theme
import theme from "../../theme.js";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";

export default function AnnotationHandler() {
    const [zoom, setZoom] = useState(false);
    const [focus, setFocus] = useState({});
    const [camPos, setCamPos] = useState({});
    const annotationArrayData = IntializeAnnotations();
    const { childPage, annotationVisibility, annotationSelection, setAnnotationSelection } = useGlobalContext();
    const [annotationVis, setAnnotationVis] = annotationVisibility;
    document.addEventListener("pointerdown", () => {
        if (zoom) {
            const allLabels = document.getElementsByClassName("content");

            for (let i = 1; i <= allLabels.length; i++) {
                document.getElementById(`label-${i}`).setAttribute("style", "visibility: hidden");
            }
            setZoom(!zoom);
        }

        // console.log(camPos);
    });
    return (
        <>
            <AnnotationsHandler
                annotations={annotationArrayData}
                zoomToView={(focusRef, camPosRef) => (setZoom(!zoom), setCamPos(camPosRef), setFocus(focusRef))}
                annotationVis={annotationVis}
            />
            <Controls zoom={zoom} focus={focus} camPos={camPos} />
        </>
    );
}

//Annotation data/info
function IntializeAnnotations() {
    var isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const annotationData = [
        {
            label_id: "label-1",
            position: new THREE.Vector3(0.3, -1.5, 1.5), //Marker Co-ordinates
            camPosition: isDesktopOrLaptop
                ? setCameraLookPos(new THREE.Vector3(7, 0, 0.2))
                : setCameraLookPos(new THREE.Vector3(15, -0, 0)), // Camera Co-ordinate [Where you want your camera to be position, when this marker is clicked]
            labelData: {
                title: "Graphene Technology",
                description:
                    "The GX4 Graphene uses this unique technology to improve durability of both the frame and the finish, while decreasing the need for cleaning and lubrication. Available with 11 and 13-round magazines, the GX4 Graphene continues to show why the Taurus GX4 is the industry leader in micro-compact pistols.",
            },
        },
        {
            label_id: "label-2",
            position: new THREE.Vector3(0, 0.25, -0.35),
            camPosition: isDesktopOrLaptop
                ? setCameraLookPos(new THREE.Vector3(7, 0, 0.2))
                : setCameraLookPos(new THREE.Vector3(15, -0, 0)), // Camera Co-ordinate [Where you want your camera to be position, when this marker is clicked]
            labelData: {
                title: "Supreme Accuracy",
                description:
                    "The barrel is the heart of any firearm, and our Taurus GX4 and GX4XL barrels are crafted with exceptional care and expertise. They offer supreme accuracy, durability, and adaptability, setting these firearms apart as reliable tools for any shooter.",
            },
        },
        {
            label_id: "label-3",
            position: new THREE.Vector3(0, -0.25, -1.85),
            camPosition: isDesktopOrLaptop
                ? setCameraLookPos(new THREE.Vector3(7, 0, 0.2))
                : setCameraLookPos(new THREE.Vector3(15, -0, 0)), // Camera Co-ordinate [Where you want your camera to be position, when this marker is clicked]
            labelData: {
                title: "Streamlined Compactness & Extended Versatility",
                description:
                    "The Taurus GX4 features a slide design that embodies compactness and concealability. For those who seek a bit more versatility without compromising on concealment, the Taurus GX4XL steps up to the plate. Its extended slide provides a longer sight radius, enhancing your ability to acquire targets quickly and accurately. ",
            },
        },
        {
            label_id: "label-4",
            position: new THREE.Vector3(0.1, 0.35, 1.6),
            camPosition: isDesktopOrLaptop
                ? setCameraLookPos(new THREE.Vector3(7, 0, 0.2))
                : setCameraLookPos(new THREE.Vector3(15, -0, 0)), // Camera Co-ordinate [Where you want your camera to be position, when this marker is clicked]
            labelData: {
                title: "Adjustable Sights",
                description:
                    "Achieve pinpoint accuracy with adjustable sights that allow you to fine-tune your point of impact. Whether you're hitting targets near or far, these sights give you the control you need.",
            },
        },
    ];

    return annotationData;
}
//loop through annotations
function AnnotationsHandler({ annotations, zoomToView, annotationVis }) {
    const mappedData = annotations.map(({ label_id, position, camPosition, labelData }, i) => (
        <Annotation
            key={i}
            index={i}
            label_id={label_id}
            labelData={labelData}
            cameraPos={camPosition}
            position={position}
            zoomToView={zoomToView}
            visibility={annotationVis}
        />
    ));

    return mappedData;
}
//Return annotation
function Annotation({ index, label_id, position, labelData, cameraPos, zoomToView, visibility, ...props }) {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const { childPage, annotationVisibility, annotationSelection, setAnnotationSelection } = useGlobalContext();
    return (
        <>
            <Html
                {...props}
                // occlude
                position={position}
                style={{
                    transition: "all 0.5s",
                    opacity: visibility ? 1 : 0,
                    transform: `scale(${visibility ? 1 : 0.25})`,
                }}
            >
                <ThemeProvider theme={theme}>
                    {/* Marker */}
                    <div
                        className="marker-outer"
                        onClick={() => {
                            zoomToView(position, cameraPos);
                            LabelVisibilityToggle(true, label_id);
                            setAnnotationSelection(index); //send to global context manager.
                        }}
                    >
                        <div className={annotationSelection == index ? "marker-inner-selected" : "marker-inner"}>
                            <Typography variant="body2" color={annotationSelection == index ? "primary" : "inherit"}>
                                i
                            </Typography>
                        </div>

                        {/* Label */}
                        {isDesktopOrLaptop && (
                            <div id={label_id} className="content">
                                <Typography className="title" variant="h1">
                                    {labelData.title}
                                </Typography>
                                <Typography variant="annotation">{labelData.description}</Typography>
                            </div>
                        )}
                    </div>
                </ThemeProvider>
            </Html>
        </>
    );
}

//Camera controls
function Controls({ zoom, focus, camPos, pos = new THREE.Vector3(), look = new THREE.Vector3() }) {
    const cameraMovementSpeed = 0.03;
    return useFrame((state, delta) => {
        if (zoom) {
            // pos.set(focus.x + camPos.x, focus.y + camPos.y, focus.z + camPos.z);//This add more dynamic moveement to focus on the annotation
            pos.set(camPos.x, camPos.y, camPos.z);
            // look.set(focus.x, focus.y, focus.z)
            // state.camera.lookAt(look) ---- Messing it up ! Dont use
            state.camera.position.lerp(pos, cameraMovementSpeed);
            state.camera.updateProjectionMatrix();
        }
        return null;
    });
}

//Calculate and return camera position
function setCameraLookPos(markerPos) {
    //z => if(z>0) z++ else z--
    //(+x,+y) => (+x++, +y++)
    //(+x,-y) => (+x++, -y--)
    //(-x,+y) => (-x--, +y++)
    const dX = 0.1;
    const dY = 0.1;
    const dZ = 0.4;
    const deltaX = markerPos.x >= 0 ? markerPos.x + dX : markerPos.x - dX;
    const deltaY = markerPos.y >= 0 ? markerPos.y + dY : markerPos.y - dY;
    const deltaZ = markerPos.z >= 0 ? markerPos.z + dZ : markerPos.z - dZ;
    return new THREE.Vector3(deltaX, deltaY, deltaZ);
}

//Access css to toggle annotation visibility
function LabelVisibilityToggle(status, l_id) {
    // console.log(status, l_id);
    const toggle = status ? "visible" : "hidden";
    const allLabels = document.getElementsByClassName("content");
    if (status) {
        // Toggle visibility for selected id label
        for (let i = 1; i <= allLabels.length; i++) {
            if (allLabels[i - 1].id == l_id) {
                document.getElementById(`label-${i}`).setAttribute("style", `visibility: ${toggle}`);
            } else {
                document.getElementById(`label-${i}`).setAttribute("style", `visibility: ${!toggle}`);
            }
        }
    } else {
        document.getElementById(`label-${l_id}`).setAttribute("style", `visibility: ${toggle}`);
    }
}
