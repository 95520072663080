//Main
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';

//MUI
import { Typography, Button, Box, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IosShareIcon from "@mui/icons-material/IosShare";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import MenuIcon from "@mui/icons-material/Menu";
import { useParams } from "react-router-dom";

//Theme
import theme from "../theme.js";
import { ThemeProvider } from "@mui/material/styles";

//Additional Files
import GX4Logo from "../assets/images/general/gx4.svg";
import GX4XLLogo from "../assets/images/general/gx4XL.svg";
import ShareModal from "./ShareModal.jsx";
import { useGlobalContext } from "./GlobalContextManager";

export default function Header(props) {
    // console.log('Header Rendered');
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const { childPage, annotationVisibility } = useGlobalContext();

    const [menusVisibility, setMenusVisibility] = useState(true);
    const [annotationVis, setAnnotationVis] = annotationVisibility;
    const [shareModalOpen, setShareModalOpen] = React.useState(false);

    const navigate = useNavigate();

    function handleMenuVisibility(value) {
        if(value){
            //turn on menu
            setMenusVisibility(true);
            props.menusVisibility(true);
        }else{
            //turn on off menu
            setMenusVisibility(false);
            props.menusVisibility(false);
        }

        if(annotationVis && !isDesktopOrLaptop){//mobile only
            //if annotations are on then lets turn off the annotations.
            handleAnnotationVisibility(false);
        }
    }

    function handleAnnotationVisibility(value) {
        if(value){
            //turn on annotations
            setAnnotationVis(value);
            props.annotationVisibility(true)
            if(menusVisibility && !isDesktopOrLaptop){//mobile only
                //if menus are on then turn off the visibility of the menu divs to make room for
                // the annotations divs, keep the menu button the same state
                //so that it shows that menus/annotation divs are visible and can be toggled later. 
                props.menusVisibility(false);
            }else if(!isDesktopOrLaptop){//mobile only
                //if menus are off already and were turning on annotation, switch the menu state
                //so that it shows that menus/annotation divs are visible and can be toggled later. 
                setMenusVisibility(true);
            }
        }else{
            //turn off annotations
            setAnnotationVis(value);
            props.annotationVisibility(value)
            if(menusVisibility && !isDesktopOrLaptop){
                //if we're switching annotations off then check if menu state was on and switch it to off 
                //so we can turn on the menu divs on later.
                setMenusVisibility(false);
            }
        }
    }

    const handleShareClickOpen = () => {
        setShareModalOpen(true);
    };

    const handleShareClose = (value) => {
        setShareModalOpen(false);
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                {isDesktopOrLaptop ? (
                    <div className="header">
                        {/* LOGO */}
                        <Box
                            component="img"
                            alt="Taurus Logo"
                            src={childPage == "GX4" ? GX4Logo : GX4XLLogo}
                            className="headerLogo"
                        />
                        {/* HEADER MENU */}
                        <div className="headerGridBtn" >
                            {/* Features */}
                            <div className="headerTxt">
                                <Button
                                    variant="h4"
                                    style={{ backgroundColor: "transparent" }}
                                    disableRipple
                                    onClick={() => {
                                        handleAnnotationVisibility(!annotationVis);
                                    }} //ON CLICK TURN ON FEATURES
                                >
                                    <RadioButtonCheckedIcon color={annotationVis ? "success" : "primary"} />
                                </Button>
                                <Typography sx={{ color: "primary.main" }} variant="body1">
                                    FEATURES
                                </Typography>
                            </div>

                            {/* Menu */}
                            <div className="headerTxt">
                                <Button
                                    variant="h4"
                                    style={{ backgroundColor: "transparent" }}
                                    disableRipple
                                    onClick={() => {
                                        handleMenuVisibility(!menusVisibility);
                                    }} //ON CLICK HIDE MENU
                                >
                                    {menusVisibility ? (
                                        <VisibilityOffIcon color="primary" />
                                    ) : (
                                        <VisibilityIcon color="primary" />
                                    )}
                                </Button>
                                <Typography sx={{ color: "primary.main" }} variant="body1">
                                    {menusVisibility ? "HIDE" : "SHOW"}
                                </Typography>
                            </div>

                            {/* Share */}
                            <div className="headerTxt">
                                <Tooltip title="Share" describeChild followCursor>
                                    <Button
                                        variant="h4"
                                        style={{ backgroundColor: "transparent" }}
                                        disableRipple
                                        onClick={handleShareClickOpen} //ON CLICK LETS SHARE THIS PAGE
                                    >
                                        <IosShareIcon color="primary" />
                                    </Button>
                                </Tooltip>
                                <Typography sx={{ color: "primary.main" }} variant="body1">
                                    SHARE
                                </Typography>
                                <ShareModal open={shareModalOpen} onClose={handleShareClose} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="header">
                            {/* LOGO */}
                            <Box 
                                component="img" 
                                alt="Taurus GX4XL Logo" 
                                src={childPage == "GX4" ? GX4Logo : GX4XLLogo}
                                className="headerLogo" />
                        </div>
                        <div className="headerFooter">
                            <div className="headerFooterGridBtn">
                                {/* Share */}
                                <div className="headerTxt">
                                    <Button
                                        variant="h4"
                                        style={{ backgroundColor: "transparent" }}
                                        disableRipple
                                        onClick={()=>navigate("/")} //ON CLICK LETS SHARE THIS PAGE
                                    >
                                        <MenuIcon color="primary" />
                                    </Button>
                                    <Typography sx={{ color: "primary.main" }} variant="body1">
                                        MENU
                                    </Typography>
                                </div>

                                {/* Features */}
                                <div className="headerTxt">
                                    <Button
                                        variant="h4"
                                        style={{ backgroundColor: "transparent" }}
                                        disableRipple
                                        onClick={() => {
                                            handleAnnotationVisibility(!annotationVis);
                                        }} //ON CLICK TURN ON FEATURES
                                    >
                                        <RadioButtonCheckedIcon color={annotationVis ? "success" : "primary"} />
                                    </Button>
                                    <Typography sx={{ color: "primary.main" }} variant="body1">
                                        FEATURES
                                    </Typography>
                                </div>

                                {/* Menu */}
                                <div className="headerTxt">
                                    <Button
                                        variant="h4"
                                        style={{ backgroundColor: "transparent" }}
                                        disableRipple
                                        onClick={() => {
                                            handleMenuVisibility(!menusVisibility);
                                        }} //ON CLICK HIDE MENU
                                    >
                                        {menusVisibility ? (
                                            <VisibilityOffIcon color="primary" />
                                        ) : (
                                            <VisibilityIcon color="primary" />
                                        )}
                                    </Button>
                                    <Typography sx={{ color: "primary.main" }} variant="body1">
                                        {menusVisibility ? "HIDE" : "SHOW"}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </ThemeProvider>
        </>
    );
}
