//Main
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';

//MUI
import { Grid, Box } from "@mui/material";

//Additional Files
import GX4 from "../assets/images/general/GX4_a.webp";
import GX4_Side from "../assets/images/general/GX4_b.webp";

import GX4XL from "../assets/images/general/GX4XL_a.webp";
import GX4XL_Side from "../assets/images/general/GX4XL_b.webp";

import GX4Logo from "../assets/images/general/gx4.svg";
import GX4XLLogo from "../assets/images/general/gx4XL.svg";

import { useGlobalContext } from "./GlobalContextManager";


export default function SelectionOptions(props) {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const { childPage } = useGlobalContext();
    
    const [GX4XLImgBool, setGX4XLImgBool] = useState(false);
    const [GX4ImgBool, setGX4ImgBool] = useState(false);
    const navigate = useNavigate();

    //Function to callback to parent to notify when a choice is selected.
    function ConfigurationPageGX4(e) {
        // navigate('/GX4')
        navigate('/GX4', { replace: true });
        navigate(0)
    }
    function ConfigurationPageGX4XL(e) {
        // navigate('/GX4XL')
        navigate('/GX4XL', { replace: true });
        navigate(0)
    }

    //Render front view or side view based on mouse hover.
    function MainGX4Img({ image, url, component }) {
        return (
            <>
                {component === "Footer" ? 
                    <Box
                        component="img"
                        sx={{
                            display: `${image === 'front' ? (GX4ImgBool ? "none" : "block") : (!GX4ImgBool ? "none" : "block")}`,
                            maxWidth: "100px",
                            height: "auto",
                            width: "auto",
                        }}
                        alt="Taurus GX4 Gun Front View"
                        src={url}
                    />
                    :
                    <Box
                        component="img"
                        sx={{
                            display: `${image === 'front' ? (GX4ImgBool ? "none" : "block") : (!GX4ImgBool ? "none" : "block")}`,
                            height: "auto",
                            width: `${isDesktopOrLaptop ? "auto" : "60vw"}`,
                        }}
                        alt="Taurus GX4 Gun Front View"
                        src={url}
                    />
                }
            </>
        );
    }
    function MainGX4XLImg({ image, url, component }) {
        return (
            <>
                {component === "Footer" ? 
                    <Box
                        component="img"
                        sx={{
                            display: `${image === 'front' ? (GX4XLImgBool ? "none" : "block") : (!GX4XLImgBool ? "none" : "block")}`,
                            maxWidth: "100px",
                            height: "auto",
                            width: "auto",
                        }}
                        alt="Taurus GX4XL Gun Front View"
                        src={url}
                    />
                    :
                    <Box
                        component="img"
                        sx={{
                            display: `${image === 'front' ? (GX4XLImgBool ? "none" : "block") : (!GX4XLImgBool ? "none" : "block")}`,
                            height: "auto",
                            width: `${isDesktopOrLaptop ? "auto" : "60vw"}`,
                        }}
                        alt="Taurus GX4XL Gun Front View"
                        src={url}
                    />
                }
            </>
        );
    }

    return (
        <div className="selOptionsScrn">
            {/* Variant for the footer vs main screen. */}
            {props.componentPage === "Footer" ? (
                // Footer Version
                <Grid
                    container
                    direction="row"
                    // sx={{ border: '1px solid red' }}
                >
                    <div className={childPage === "GX4XL" ? "selOptSubGridFooter selectedSubGrid" : "selOptSubGridFooter"}>
                        <Grid
                            item
                            // sx={{ border: '1px solid blue' }}
                            onClick={() => ConfigurationPageGX4XL()}
                            onMouseEnter={() => setGX4XLImgBool(true)}
                            onMouseLeave={() => setGX4XLImgBool(false)}
                            // className='selOptSubGridFooter'
                        >
                            <MainGX4XLImg image="front" url={GX4XL} component={props.componentPage}/>
                            <MainGX4XLImg image="side" url={GX4XL_Side} component={props.componentPage} />
                        </Grid>
                    </div>
                    <div className={childPage === "GX4" ? "selOptSubGridFooter selectedSubGrid" : "selOptSubGridFooter"}>
                        <Grid
                            item
                            // sx={{ border: '1px solid blue' }}
                            onClick={() => ConfigurationPageGX4()}
                            onMouseEnter={() => setGX4ImgBool(true)}
                            onMouseLeave={() => setGX4ImgBool(false)}
                            // className='selOptSubGridFooter'
                        >
                            <MainGX4Img image="front" url={GX4} component={props.componentPage}/>
                            <MainGX4Img image="side" url={GX4_Side} component={props.componentPage} />
                        </Grid>
                    </div>
                </Grid>
            ) : (
                // Main screen Version
                <div className="selOptMain">
                    <Grid container spacing={2} direction={isDesktopOrLaptop?"row":"column"}>
                        {/* Img/Logo One */}
                        <Grid
                            // sx={{ border: '1px solid Blue' }}
                            item
                            onClick={() => ConfigurationPageGX4XL()}
                            onMouseEnter={isDesktopOrLaptop ? () => setGX4XLImgBool(true):null}
                            onMouseLeave={isDesktopOrLaptop ? () => setGX4XLImgBool(false):null}
                            // xs={`${isDesktopOrLaptop ? 6 : 12}`}
                            xs={6}
                            className="selOptMainMobile"
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "auto",
                                    width: "auto",
                                }}
                                alt="Taurus GX4XL Logo"
                                src={GX4XLLogo}
                            />
                            <MainGX4XLImg image="front" url={GX4XL} component={props.componentPage}/>
                            <MainGX4XLImg image="side" url={GX4XL_Side} component={props.componentPage}/>
                        </Grid>
                        
                        {/* Img/Logo Two */}
                        <Grid
                            // sx={{ border: '1px solid Green' }}
                            item
                            onClick={() => ConfigurationPageGX4()}
                            onMouseEnter={isDesktopOrLaptop ? () => setGX4ImgBool(true):null}
                            onMouseLeave={isDesktopOrLaptop ? () => setGX4ImgBool(false):null}
                            // xs={`${isDesktopOrLaptop ? 6 : 12}`}
                            xs={6}
                            className="selOptMainMobile"
                        >
                            <Box
                                component="img"
                                sx={{
                                    height: "auto",
                                    width: "auto",
                                }}
                                alt="Taurus Logo"
                                src={GX4Logo}
                            />
                            <MainGX4Img image="front" url={GX4} component={props.componentPage}/>
                            <MainGX4Img image="side" url={GX4_Side} component={props.componentPage}/>
                        </Grid>

                    </Grid>
                </div>
            )}
        </div>
    );
}
