//Main
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function UseStateParams(initialState, paramsName, serialize, deserialize) {
    const navigation = useNavigate();
    const location = useLocation();
    const search = new URLSearchParams(location.search);

    const existingValue = search.get(paramsName);
    const [state, setState] = useState(existingValue ? deserialize(existingValue) : initialState);

    useEffect(() => {
        // Updates state when user navigates backwards or forwards in browser history
        if (existingValue && deserialize(existingValue) !== state) {
            setState(deserialize(existingValue));
        }
    }, [existingValue]);

    const onChange = (s,f) => {
        // console.log("From onChange")
        // console.log(s,f)

        //Filter out default values. So they get deleted on the url
        function CheckDefaultSelection(param){
            // Convert the number to a string to work with individual digits
            const numberStr = param.toString();
    
            // Remove the first character (digit)
            const modifiedStr = numberStr.substring(1);
            
            // Remove any zeros in the modified string
            const cleanedStr = modifiedStr.replace(/0/g, '');
    
            // Convert the cleaned string back to a number
            const cleanedNumber = parseInt(cleanedStr, 10);
    
            if(isNaN(cleanedNumber)){
                return null;
            }else{
                return param;
            }
        }

        const UrlDefaultCheck = CheckDefaultSelection(s);

        setState(s);
        const searchParams = new URLSearchParams(location.search);
        const pathname = location.pathname;

        // console.log("-------");
        // console.log(searchParams);
        // console.log("-------");

        if(UrlDefaultCheck == null){
            // console.log('Deleting URL params: ');
            // console.log(s);
            // console.log(f);
            // searchParams.delete(s);
            searchParams.delete(f);
            navigation({ pathname, search: searchParams.toString() });
        }else{
            // console.log('Setting URL params : '+paramsName);
            searchParams.set(paramsName, serialize(s));
            navigation({ pathname, search: searchParams.toString() });
        }
        // console.log("Current search param:");
        // console.log(searchParams.toString());
    };

    return [state, onChange];

}
