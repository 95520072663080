//Main
import React, { Suspense } from "react";

//Additional Files
import Model from "./Model.jsx";
import GetSearchParams from "../GetSearchParams.jsx";
import Loader from "./Loader";
import { useGlobalContext } from "../GlobalContextManager";

export default function ModelHandler() {
    const { childPage } = useGlobalContext();

    let Data = null;
    if(childPage === 'GX4'){
        Data = require("../../API/Api_AWSModels.json")
    }else{
        Data = require("../../API/Api_AWSModels_GX4XL.json")
    }
    //Specify how many models (Base, BackStrap, Barrels, Ironsights, Laser&Sights, Magazine, Optics, Slides) = 8
    const numOfModels = 8;
    const Models = Array.from({ length: numOfModels }, (_, index) => index);
    return (
        <>
            {Models.map((index, id) => (
                <Suspense key={id} fallback={<Loader />}>
                    {/* This skips the optics if the slide selection is incompatible */}
                    {id==5 && GetSearchParams(6)!==700000 ? null :  <Model Data={Data.Model[GetSearchParams(id)]} />}
                    {/* <Model Data={Data.Model[GetSearchParams(id)]} /> */}
                </Suspense>
            ))}
        </>
    );
}
