import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    Typography,
    Tooltip,
    IconButton
} from "@mui/material/";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

import "./ShareModal.css";
import URLCopy from "./URLCopy";

export default function ShareModal(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog
            className="shareDialog"
            onClose={handleClose}
            open={props.open}
            PaperProps={{
                style: {
                    backgroundColor: "#0D0F10",
                    boxShadow: "none",
                    backgroundImage: "none",
                    minWidth: "450px",
                    maxWidth:"500px",
                    borderRadius:"0px"
                }
            }}
            BackdropProps={{ 
                style: { 
                    backgroundColor: "rgba(0, 0, 0, 0.25)" 
                } 
            }}
        >
            <DialogTitle className="shareDialogTitle">
                <Typography sx={{ color: "primary.main" }} variant="h1">
                    Share
                </Typography>
                <IconButton aria-label="delete"
                    disableRipple
                    onClick={handleClose}
                > 
                    <ControlPointOutlinedIcon sx={{ transform: "rotate(-0.125turn)" }} color="primary" />
                </IconButton>
            </DialogTitle>

            <DialogActions className="shareDialogAction">
                <a className="linkURL" href={"https://api.whatsapp.com/send?text=Check%20out%20my%20configuration!%0D%0A" + window.location.href} data-action="share/whatsapp/share" target="_blank">
                    <div className="shareIcnBtns">
                        <Button
                            variant="h4"
                            style={{ backgroundColor: "transparent" }}
                            disableRipple
                            onClick={() => {
                                // console.log("HI");
                            }} //ON CLICK HIDE MENU
                        >
                            <WhatsAppIcon color="primary" />
                        </Button>
                        <Typography sx={{ color: "primary.main" }} variant="body1">
                            WhatsApp
                        </Typography>
                    </div>
                </a>
                <a className="linkURL" href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} target="_blank">
                    <div className="shareIcnBtns">
                        <Button
                            variant="h4"
                            style={{ backgroundColor: "transparent" }}
                            disableRipple
                            onClick={() => {
                                // console.log("HI");
                            }} //ON CLICK HIDE MENU
                        >
                            <FacebookIcon color="primary" />
                        </Button>
                        <Typography sx={{ color: "primary.main" }} variant="body1">
                            Facebook
                        </Typography>
                    </div>
                </a>
                <a className="linkURL" href={"https://twitter.com/intent/tweet?text=Check%20out%20my%20configuration!%0D%0A" + window.location.href} target="_blank">
                    <div className="shareIcnBtns">
                        <Button
                            variant="h4"
                            style={{ backgroundColor: "transparent" }}
                            disableRipple
                            onClick={() => {
                                // console.log("HI");
                            }} //ON CLICK HIDE MENU
                        >
                            <TwitterIcon color="primary" />
                        </Button>
                        <Typography sx={{ color: "primary.main" }} variant="body1">
                            Twitter
                        </Typography>
                    </div>
                </a>
                <a className="linkURL" href={"mailto:?subject=Taurus GX4 Configurator&body=Check out my configuration!%0D%0A" + window.location.href} target="_blank">
                    <div className="shareIcnBtns">
                        <Button
                            variant="h4"
                            style={{ backgroundColor: "transparent" }}
                            disableRipple
                            onClick={() => {
                                // console.log("HI");
                            }} //ON CLICK HIDE MENU
                        >
                            <EmailIcon color="primary" />
                        </Button>
                        <Typography sx={{ color: "primary.main" }} variant="body1">
                            Email
                        </Typography>
                    </div>
                </a>
            </DialogActions>

            <DialogContent>
                <DialogContentText className="shareDialogContentText">
                    <Typography sx={{ color: "primary.main" }} variant="body1">
                        Share your configuration with others.
                        </Typography>
                    </DialogContentText>
                <Box className="shareURLDiv">
                    <Typography className="shareURLDivText" sx={{ color: "primary.main" }} variant="body1">
                        <a className="linkURL rawLink" href={window.location.href} target="_blank">{window.location.href} </a>
                        <Tooltip title="Copy to Clipboard" describeChild followCursor>
                            <Button className="copyBtn" onClick={URLCopy}>
                                <Typography sx={{ color: "primary.main" }} variant="body2">
                                    Copy
                                </Typography>
                            </Button>
                        </Tooltip>
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
