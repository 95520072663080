//Main
import React, { useState } from "react";

//Mui
import {
    Box,
    Divider,
    Grid,
    Collapse,
    FormGroup,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

//Additional Files
import { useGlobalContext, useGlobalContextUpdate } from "./GlobalContextManager";

export default function AccessoriesContent(props) {
    //init variables and functions to recieve and send to GlobalContext Manager
    const { equipment, childPage } = useGlobalContext();
    let Data = null;
    if(childPage === 'GX4'){
        Data = require("../API/Api_Accessories_GX4.json")
    }else{
        Data = require("../API/Api_Accessories_GX4XL.json")
    }
    // console.log(Data)
    // console.log(Data)
    const URLUpdate = useGlobalContextUpdate();
    //Current accessorie drawer selection
    const [config, setConfig] = useState(null);

    //We'll be updating the GlobalContextManager by sending: AccessoryID(int), UID(int), ObjectID(int)
    function EquipAccessory(accessoryTab, UID, objID) {
        //check if the current selection is already a selected if not...
        // console.log(equipment[accessoryTab], UID)
        if (equipment[accessoryTab] !== UID) {
            // Update the GlobalContextManager with current selection (function InternalGlobalContextFunction(UID, accessory, objID))
            URLUpdate(UID, accessoryTab, objID);
            //Send values to parent
            // props.AccessorySelection([accessoryTab, UID, objID]);
        }
    }

    return (
        <>
            <div className={props.isDesktopOrLaptop? "accessoriesDiv" : "accessoriesDivMobile"}>
                <List>
                    {Data.Accessories.map((data, id) => {
                        // console.log(data);
                        return (
                            <li key={data.Name}>
                                <div className="accessorieslistItemDiv">
                                    <ListItemButton
                                        className="accessorieslistItem"
                                        onClick={() => {
                                            //This is only updating what tab is currently selected
                                            config === id ? setConfig(null) : setConfig(id);
                                        }}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography variant="h3" className="mainDrawerTxt">
                                                    {data.Name}
                                                </Typography>
                                            }
                                        />
                                        
                                        {id === 5 && equipment[6] !== 700000 ? null : config === id  ? (
                                            <RemoveCircleOutlineIcon color="primary"/>
                                        ) : (
                                                <AddCircleOutlineIcon color="primary"/>
                                        )}
                                        {id === 5 && equipment[6] !== 700000 && (
                                            <Typography color="gray" variant="body2" className="mainDrawerTxt">
                                                INCOMPATIBLE W/ CURRENT SLIDE
                                            </Typography>
                                        )}
                                    </ListItemButton>
                                </div>
                                <Collapse in={id === 5 && equipment[6] !== 700000 ? null : config === id } timeout="auto" >
                                    <List className="drawerLists" component="div" disablePadding>
                                        <FormGroup>
                                            {data.Objects.map((objData, objID) => (
                                                <div key={objData.UID}>
                                                    <Box
                                                        component="span"
                                                        key={objID}
                                                        onClick={() => {
                                                            //This should update the equipment selection
                                                            EquipAccessory(id, objData.UID, objID);
                                                            // console.log(id, objData.UID, objID);
                                                        }}
                                                        // TODO Continue Working on URL Selection update
                                                        className={
                                                            equipment[id] === objData.UID
                                                                ? "CustomConfigBoxesSel accessoriesBoxes"
                                                                : "accessoriesBoxes"
                                                        }
                                                        // className={equipmentURL[id] === objData.UID.toString()? "CustomConfigBoxesSel accessoriesBoxes": "accessoriesBoxes"}
                                                        sx={{ flexgrow: 1 }}
                                                    >
                                                        <Grid container alignItems="center">
                                                            <Box
                                                                component="img"
                                                                className="accessorieImg"
                                                                alt={objData.URLAltTxt}
                                                                src={objData.URL}
                                                            />
                                                            <Grid item xs={9} sx={{ pl: 0 }}>
                                                                <Typography variant="body2">
                                                                    {objData.Company}
                                                                </Typography>
                                                                <Typography>{objData.ProductName}</Typography>
                                                                <Typography variant="subtitle1">
                                                                    ${objData.Price}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Divider />
                                                </div>
                                            ))}
                                        </FormGroup>
                                    </List>
                                </Collapse>
                            </li>
                        );
                    })}
                </List>
            </div>
        </>
    );
}
