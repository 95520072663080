//Main
import React from 'react'

//MUI/Theme
import RefreshIcon from '@mui/icons-material/Refresh';
import theme from "../theme.js";
import { Typography } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";

//Additional Files
import "./MobileLandscape.css";

export default function MobileLandscape() {
  return (
    <>
        <ThemeProvider theme={theme}>
            <div className="mobileLandscapeDiv">
                <div className="mobileLandscapeSubDiv">
                    <div className='mobileLandscapeContent'>
                        <Typography className='mobileLandscapeTxt'>Please rotate your device.</Typography>
                        <RefreshIcon color="primary" />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    </>
  )
}
