import "../../App";
import React, { useRef, useState, useEffect, useFrame } from "react";
import { useMediaQuery } from "react-responsive";
import { Canvas, extend, useThree } from "@react-three/fiber";
import * as THREE from "three";
import {
    GizmoHelper,
    GizmoViewport,
    Grid,
    Stats,
    OrbitControls,
    Environment,
    useProgress,
    Lightformer,
    AdaptiveDpr,
    AdaptiveEvents,
    PerformanceMonitor,
    CameraControls,
    Bounds, 
    useBounds, 
    Preload,
} from "@react-three/drei";

import { Color, Depth } from "lamina";
import { useControls, button, buttonGroup, folder, Leva } from "leva";
import { geometry } from "maath";

// import ModelComponent from './ModelComponent'
import ModelHandler from "./ModelHandler";
import LightFormer from "./LightFormer";
import PointLight from "./PointLight";
import PostProcessing from "./PostProcessing";
import AnnotationHandler from "./AnnotationHandler";
import EnvironmentHandler from "./EnvironmentHandler";

extend(geometry);

const { DEG2RAD } = THREE.MathUtils;

export default function ThreeCanvas() {
    // console.log("ThreeCanvas Loaded")
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const { progress } = useProgress();
    const cameraControlsRef = useRef();
    const [dpr, setDpr] = useState(1.5)

    function SelectToZoom({ children }) {
        const api = useBounds();
        return (
          <group onClick={(e) => (e.stopPropagation(), e.delta <= 2 && api.refresh(e.object).fit())} onPointerMissed={(e) => e.button === 0 && api.refresh().fit()}>
                {children}
        </group>
        )
    }
    //UI Config ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* *******
    const { asciiRenderer, pathTracer } = useControls("Renderer Control", {
        asciiRenderer: false,
        pathTracer: false,
    });
    const { minDistance, enabled, verticalDragToForward, dollyToCursor, infinityDolly } = useControls(
        "Camera Controls",
        {
            thetaGrp: buttonGroup({
                label: "rotate theta",
                opts: {
                    "+45º": () => cameraControlsRef.current?.rotate(45 * DEG2RAD, 0, true),
                    "-90º": () => cameraControlsRef.current?.rotate(-90 * DEG2RAD, 0, true),
                    "+360º": () => cameraControlsRef.current?.rotate(360 * DEG2RAD, 0, true),
                },
            }),
            phiGrp: buttonGroup({
                label: "rotate phi",
                opts: {
                    "+20º": () => cameraControlsRef.current?.rotate(0, 20 * DEG2RAD, true),
                    "-40º": () => cameraControlsRef.current?.rotate(0, -40 * DEG2RAD, true),
                },
            }),
            truckGrp: buttonGroup({
                label: "truck",
                opts: {
                    "(1,0)": () => cameraControlsRef.current?.truck(1, 0, true),
                    "(0,1)": () => cameraControlsRef.current?.truck(0, 1, true),
                    "(-1,-1)": () => cameraControlsRef.current?.truck(-1, -1, true),
                },
            }),
            dollyGrp: buttonGroup({
                label: "dolly",
                opts: {
                    1: () => cameraControlsRef.current?.dolly(1, true),
                    "-1": () => cameraControlsRef.current?.dolly(-1, true),
                },
            }),
            zoomGrp: buttonGroup({
                label: "zoom",
                opts: {
                    // '/2': () => cameraControlsRef.current?.zoom(camera.zoom / 2, true),
                    // '/-2': () => cameraControlsRef.current?.zoom(-camera.zoom / 2, true)
                },
            }),
            minDistance: { value: 0 },
            moveTo: folder(
                {
                    vec1: { value: [-1, -1, 2], label: "vec" },
                    "moveTo(…vec)": button((get) =>
                        cameraControlsRef.current?.moveTo(...get("Camera Controls.moveTo.vec1"), true),
                    ),
                },
                { collapsed: true },
            ),
            // 'fitToBox(mesh)': button(() => cameraControlsRef.current?.fitToBox(meshRef.current, true)),
            setPosition: folder(
                {
                    vec2: { value: [-6, 1, 0.5], label: "vec" },
                    "setPosition(…vec)": button((get) =>
                        cameraControlsRef.current?.setPosition(...get("Camera Controls.setPosition.vec2"), true),
                    ),
                },
                { collapsed: true },
            ),
            setTarget: folder(
                {
                    vec3: { value: [0, 0.8, 0.6], label: "vec" },
                    "setTarget(…vec)": button((get) =>
                        cameraControlsRef.current?.setTarget(...get("Camera Controls.setTarget.vec3"), true),
                    ),
                },
                { collapsed: true },
            ),
            setLookAt: folder(
                {
                    vec4: { value: [4, 1, 4], label: "position" },
                    vec5: { value: [0, 0.5, 0.5], label: "target" },
                    "setLookAt(…position, …target)": button((get) =>
                        cameraControlsRef.current?.setLookAt(
                            ...get("Camera Controls.setLookAt.vec4"),
                            ...get("Camera Controls.setLookAt.vec5"),
                            true,
                        ),
                    ),
                },
                { collapsed: true },
            ),
            lerpLookAt: folder(
                {
                    vec6: { value: [-3, 0, -1], label: "posA" },
                    vec7: { value: [1, 1, 0], label: "tgtA" },
                    vec8: { value: [-2, 2, 5], label: "posB" },
                    vec9: { value: [0, 0, 1], label: "tgtB" },
                    t: { value: Math.random(), label: "t", min: 0, max: 1 },
                    "f(…posA,…tgtA,…posB,…tgtB,t)": button((get) => {
                        return cameraControlsRef.current?.lerpLookAt(
                            ...get("Camera Controls.lerpLookAt.vec6"),
                            ...get("Camera Controls.lerpLookAt.vec7"),
                            ...get("Camera Controls.lerpLookAt.vec8"),
                            ...get("Camera Controls.lerpLookAt.vec9"),
                            get("Camera Controls.lerpLookAt.t"),
                            true,
                        );
                    }),
                },
                { collapsed: true },
            ),
            saveState: button(() => cameraControlsRef.current?.saveState()),
            reset: button(() => cameraControlsRef.current?.reset(true)),
            enabled: { value: true, label: "controls on" },
            verticalDragToForward: { value: false, label: "vert. drag to move forward" },
            dollyToCursor: { value: false, label: "dolly to cursor" },
            infinityDolly: { value: false, label: "infinity dolly" },
        },
        { collapsed: true },
    );
    const { Controls, ...controlConfig } = useControls(
        "Grid Control",
        {
            position: [0, -2.55, 0],
            gridSize: [1, 1],
            cellSize: { value: 0.25, min: 0, max: 10, step: 0.05 },
            cellThickness: { value: 1, min: 0, max: 5, step: 0.1 },
            cellColor: "#6b7f82",
            sectionSize: { value: 0.5, min: 0, max: 10, step: 0.1 },
            sectionThickness: { value: 1.5, min: 0, max: 5, step: 0.1 },
            sectionColor: "#57635f",
            fadeDistance: { value: 31, min: 0, max: 100, step: 1 },
            fadeStrength: { value: 1, min: 0, max: 1, step: 0.1 },
            followCamera: false,
            infiniteGrid: true,
        },
        { collapsed: true },
    );
    const { LightCol1, LightCol2, L1Intensity, L2Intensity } = useControls(
        "Light Control",
        {
            LightCol1: { value: "#dee7dd" },
            L1Intensity: { value: 15, min: 0, max: 20, step: 1 },
            LightCol2: { value: "#eaede9" },
            L2Intensity: { value: 15, min: 0, max: 20, step: 1 },
        },
        { collapsed: true },
    );
    const { animPlay, animControls } = useControls(
        "Animation Control",
        {
            animPlay: false,
            animControls: { value: 1.0, min: -1, max: 1, step: 2 },
        },
        { collapsed: true },
    );
    const { annotationVisbility } = useControls(
        "Annotations Control",
        {
            annotationVisbility: false,
        },
        { collapsed: true },
    );
    const { sliderTrans, baseTrans, magTrans } = useControls(
        "Transparency Control",
        {
            sliderTrans: { value: 1.0, min: 0, max: 1, step: 0.1 },
            baseTrans: { value: 1.0, min: 0, max: 1, step: 0.1 },
            magTrans: { value: 1.0, min: 0, max: 1, step: 0.1 },
        },
        { collapsed: true },
    );
    const { modelOne, modelTwo } = useControls(
        "Models Control",
        {
            modelOne: false,
            modelTwo: false,
        },
        { collapsed: true },
    );
    //******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* ******* *******
    // console.log('Camera position: ', cameraControlsRef.current);
    // useEffect(() => {
    //   }, [])


    return (
        <div className="App">
            <Leva
                oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
                hideTitleBar // default = false, hides the GUI header
                collapsed // default = false, when true the GUI is collpased
                hidden // default = false, when true the GUI is hidden
            />

            {/* TODO: Change camera position to be dynamic and react to overall bounding box of the 3D model in view. */}
            {/* The flat option would use THREE.NoToneMapping instead of THREE.ACESFilmicToneMapping*/}
            <Canvas flat className="Canvas" camera={{ position: [7, -3.5, -3.5], fov: 35 }} dpr={dpr}>
                {/* <PerformanceMonitor factor={1} onChange={({ factor }) => setDpr(Math.round(0.5 + 1.5 * factor, 1))} /> */}
                {/* Adaptive Dpr Changes the resolution once you move the camera around */}
                {/* <AdaptiveDpr pixelated /> */}
                <AdaptiveEvents />
                {/* stats for helping gauge performance */}
                {/* <Stats /> */}
                {/* Gizmo for helping view the transform of the scene */}
                {/* <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
                    <GizmoViewport axisColors={["#9d4b4b", "#2f7f4f", "#3b5b9d"]} labelColor="white" />
                </GizmoHelper> */}
                {/* <Grid args={Controls} {...controlConfig} /> */}

                {/* controls for accessing the camera and orbiting in the viewport. */}
                {/* Todo: WHEN HAVING CAMERA CONTROLS ON THE ORBIT CONTROLS ARE STILL USED ON THE MOVEMENT/TRANSLATION OF THE TRANSFORM HELPERS */}
                {/* <CameraControls
                    ref={cameraControlsRef}
                    minDistance={minDistance}
                    enabled={enabled}
                    verticalDragToForward={verticalDragToForward}
                    dollyToCursor={dollyToCursor}
                    infinityDolly={infinityDolly}
                    makeDefault
                /> */}

                <OrbitControls
                    ref={cameraControlsRef}
                    makeDefault
                    target={[0, -1, -0.15]}
                    // enablePan ={false}
                    enableDamping={true}
                    regress
                    maxDistance={isDesktopOrLaptop?10:20}//how far you can dolly based on mobile or desktop view
                    minDistance={isDesktopOrLaptop?3:10}//how near you can dolly based on mobile or desktop view
                    rotateSpeed ={0.4}
                />
                
                {/* Lighting */}
                <ambientLight />
                <EnvironmentHandler/>
                <PointLight color={LightCol1} intensity={L1Intensity} position={[2, 2, 6]} />
                <PointLight color={LightCol2} intensity={L2Intensity} position={[4, 1.75, -2.75]} />
                <PointLight color={LightCol1} intensity={L1Intensity} position={[-4, 2, 6]} />
                <PointLight coslor={LightCol2} intensity={L2Intensity} position={[-2, 1.75, -1.75]} />
                {/* <PointLight color = {LightCol1} intensity = {L1Intensity} position = {[-1.5, 1.5, 2]} /> */}
                {/* <PointLight color = {LightCol2} intensity = {L2Intensity} position = {[-1, -2, -1.75]} /> */}

                {/* TODO: Bounding box currently focuses on the last loaded object rather than all general objects causing issues on initialization. 
                Current fix is to useProgress() on the fit property so that it focuses on the entire thing after load. */}
                <Bounds fit={progress == 100 ? true : false} observe margin={1.2} damping={3}> 
                    <SelectToZoom>
                        <ModelHandler />
                        <Preload all />
                    </SelectToZoom>
                </Bounds>
                <AnnotationHandler/>
                <PostProcessing />
            </Canvas>
        </div>
    );
}


