//Main
import * as React from "react";
import { useMediaQuery } from "react-responsive";

// MUI
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, Button, Drawer } from "@mui/material/";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

//Theme
import theme from "../theme.js";
import { ThemeProvider } from "@mui/material/styles";

//Additional Files
import AccessoriesContent from "./AccessoriesContent.jsx";

export default function Accessories(props) {
    // console.log("Accessories Rendered");
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });

    const [openRight, setOpenRight] = React.useState(false);

    const toggleDrawerRight = (newOpen) => (event) => {
        setOpenRight(newOpen);
        //event.stopPropagation is used to stop bubbling from happenning otherwise when a child button is clicked it would also fire off the parent button
        event.stopPropagation();
    };

    function toggleDrawerRightFunc(newOpen) {
        setOpenRight(newOpen);
    }

    const StyledBox = styled(Box)(({ theme }) => ({
        width: "100%",
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
    }));

    return (
        <ThemeProvider theme={theme}>
            {/* RIGHT DRAWER */}
            {/* We can switch to Swipable drawer for Mobile */}
            {isDesktopOrLaptop ? (
                <Drawer
                    anchor="right"
                    open={openRight}
                    onClose={toggleDrawerRight(false)}
                    sx={{ display: `${props.Visibility ? "intial" : "none"}` }}
                    // disableSwipeToOpen={false}
                    ModalProps={{
                        //TODO on keep mounted, when the screen is resized, the drawer is visible and is extended. (unwanted behavior) Perhaps switch to a button on the side rather than a long div panel.
                        keepMounted: true,
                    }}
                    variant="persistent"
                >
                    <StyledBox
                        onClick={toggleDrawerRight(true)}
                        sx={{ borderLeft: `${openRight ? "none" : "solid #5C6D70 1px"}` }}
                        className={openRight ? "rightDrawerDiv" : "rightDrawerDivClosed"}
                    >
                        {openRight ? <div className="rightDrawerLine"></div> : null}
                        {openRight && (
                            <div className ="CloseArrowBtnDiv"> 
                                {isDesktopOrLaptop && (
                                    <div className="pullerRight CloseArrowBtn" sx={{ zIndex: "999" }}/>// onClick={toggleDrawerRight(false)} />
                                )}

                                <div className="CloseArrowBtnDivAreaR" onClick={toggleDrawerRight(false)}/>
                            </div>
                        )}
                        <Typography className="rightDrawerTxt" variant="subtitle2" sx={{ color: "primary.main" }}>
                            {openRight ? null : "ACCESSORIES"}
                        </Typography>
                        {openRight && (
                            <AccessoriesContent
                                isDesktopOrLaptop={isDesktopOrLaptop}
                                ToggleRightDrawer={(value) => {
                                    toggleDrawerRightFunc(value);
                                }}
                                AccessorySelection={(value) => {
                                    props.AccessorySel(value);
                                }}
                            />
                        )}
                    </StyledBox>
                </Drawer>
            ) : (
                <>
                    {!openRight ? (
                        // Open Accessories Tab Button
                        <Button
                            onClick={toggleDrawerRight(true)}
                            sx={{
                                position: "absolute",
                                bottom: "85px",
                                width: "100vw",
                                height: "50px",
                                zIndex: 99,
                                borderTop: "solid 1px #5C6D70",
                                background: "rgba(255, 255, 255, 0.05)",
                                backdropFilter: "blur(5px)",
                                display: `${props.Visibility ? "intial" : "none"}`,
                            }}
                        >
                            <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
                                ACCESSORIES
                            </Typography>
                        </Button>
                    ):
                    !isDesktopOrLaptop && (//If mobile
                        <div className="mobileDrawerContentBtn">
                            <div  className="mobileDrawerContentBtnSubDiv">
                            <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                                ACCESSORIES
                            </Typography>
        
                            <IconButton
                                className="mobileDrawerCloseBtn"
                                disableRipple
                                onClick={() => toggleDrawerRightFunc(false)}
                            >
                                <ControlPointOutlinedIcon sx={{ transform: "rotate(-0.125turn)" }} color="primary" />
                            </IconButton>
                            </div>
                        </div>
                    )
                    }
                    <div className={openRight ? "rightDrawerDivMobile" : "rightDrawerDivMobileClosed"}>
                        <AccessoriesContent
                            isDesktopOrLaptop={isDesktopOrLaptop}
                            ToggleRightDrawer={(value) => {
                                toggleDrawerRightFunc(value);
                            }}
                            AccessorySelection={(value) => {
                                props.AccessorySel(value);
                            }}
                        />
                    </div>
                </>
            )}
        </ThemeProvider>
    );
}
