//Main
import React from "react";
import { useMediaQuery } from "react-responsive";

//Additional Files
import Header from "./Header";
import Overview from "./Overview";
import Accessories from "./Accessories";
import Model from "./3DComponents/Model";
import ThreeCanvas from "./3DComponents/ThreeCanvas";
import Footer from "./Footer";
// import PlaceHolderPic from "./PlaceHolderPic";
import MobileAnnotationHandler from "./MobileAnnotationHandler";

//Theme
import theme from "../theme.js";
import { ThemeProvider } from "@mui/material/styles";

export default function MainUI() {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const [menusVisibility, setMenuVisability] = React.useState(true);
    const [annotationVisibility, setAnnotationVisibility] = React.useState(false);
    const [accessorySel, setAccessorySel] = React.useState(null);

    // const threeViewer = useMemo(() => {
    //     return(
    //         <ThreeCanvas/>
    //     );
    // }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                <Header menusVisibility={(value) => { setMenuVisability(value); }} annotationVisibility={(value) => { setAnnotationVisibility(value); }}/>
                {!isDesktopOrLaptop && <MobileAnnotationHandler annotationVisibility = {annotationVisibility} />}
                <Overview Visibility={menusVisibility} />
                <Accessories Visibility={menusVisibility} AccessorySel={(value) => {}} />
                {isDesktopOrLaptop && <Footer />}
                {/* {threeViewer} */}
                <ThreeCanvas />
                {/* <PlaceHolderPic /> */}
            </ThemeProvider>
        </>
    );
}
