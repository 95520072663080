import React, { useContext, useEffect, useState, useCallback } from "react";
import useStateParams from "./UseStateParams";
import GetSearchParams from "./GetSearchParams";
import { useIsMount } from "./useIsMount";
// import AccessoriesAPI from "../API/Api_Accessories_GX4.json";

import { useLocation } from 'react-router-dom';

const Context = React.createContext();
const UpdateContext = React.createContext();

//Provides values to children components
export function useGlobalContext() {
    return useContext(Context);
}

//Updates values through a function
export function useGlobalContextUpdate() {
    return useContext(UpdateContext);
}

export function GlobalContextProvider({ children }) {
    const isMount = useIsMount(); //Function that runs and returns a bool on initial mount
    const location = useLocation();
    const [childPage, setChildPage] = useState(location.pathname.replace("/", ""));
    let AccessoriesAPI = null;
    if(childPage == 'GX4'){
        AccessoriesAPI = require("../API/Api_Accessories_GX4.json")
    }else{
        AccessoriesAPI = require("../API/Api_Accessories_GX4XL.json")
    }
    const arrayLength = AccessoriesAPI.Accessories.length; //This number is the length of categorized aaccessories
    const array = Array.from({ length: arrayLength }, (_, index) => GetSearchParams(index)); //Set up initialized array by running GetSearchParams() for each accessory category
    const [equipment, setEquipment] = useState(array); //current overall equipment selection
    const [config, setConfig] = useState(null); //current accessory category selection
    const [UID, setUID] = useState(null); //current accessory selection
    const [annotationVisibility, setAnnotationVisibility] = useState(false)
    const [annotationSelection, setAnnotationSelection] = useState(null)

    
    // console.log('%c location: '+childPage , 'background: #222; color: #bada55')

    //Run the useStateParams to update URL
    const [URLParam, setURLParam] = useStateParams(
        equipment[config], //initial state of the hook,
        config, //name of the query param that will be shown in the url
        (s) => s.toString(), //function: will take the state and turn it into a url friendly string
        (s) => (Number(s) !== Number.NaN ? Number(s) : 10), //function: will take the string and transform it back to the state
    );

    //Saving out an array of the equipment based off index based off of their UID => object Index
    const equipmentIndex = equipment.map((number) => {
        // Convert the number to a string to work with individual digits
        const numberStr = number.toString();
        // Remove the first character (digit)
        const modifiedStr = numberStr.substring(1);
        // Remove any zeros in the modified string
        const cleanedStr = modifiedStr.replace(/0/g, "");
        // Convert the cleaned string back to a number
        const cleanedNumber = parseInt(cleanedStr, 10);
        if (isNaN(cleanedNumber)) {
            return 0;
        } else {
            return cleanedNumber;
        }
    });

    //Totaling the overall price of the configuration
    const equipmentPrice = equipmentIndex.reduce((accumulator, currentValue, id) => {
        // console.log(accumulator, AccessoriesAPI.Accessories[id].Objects[equipmentIndex[id]].Price);
        // return Math.round(100*(accumulator + AccessoriesAPI.Accessories[id].Objects[equipmentIndex[id]].Price))/100;
        return parseFloat((accumulator + AccessoriesAPI.Accessories[id].Objects[equipmentIndex[id]].Price).toFixed(2));
    });

    //Update value changes
    function GlobalContextUpdateFunction(UID, accessory, objID) {
        // Create a copy of the array & Modify the desired index
        const updatedEquipment = [...equipment];
        //manually update the optics whenever it's incompatible because of the slide.
        if (accessory == 6) {
            if (UID !== 700000) {
                updatedEquipment[5] = 600000;
            }
        }
        updatedEquipment[accessory] = UID;

        //Check to see if any update has occured otherwise we can skip updating.
        if (updatedEquipment[accessory] !== equipment[accessory]) {
            // Update the state with the modified array
            setEquipment(updatedEquipment);

            //set the selecetions accessorie tab
            setConfig(accessory);

            //updating the UID will trigger the useEffect running useStateParams ( setURLParam(UID, accessory); )
            setUID(UID);
        }
    }

    //When the GlobalContextUpdateFunction updates the last value, we listen to that change and then update the URL
    useEffect(() => {
        if (!isMount) {
            //Skip initial mount
            if (config == 6) {
                if (UID !== 700000) {
                    setURLParam(600000, 5);
                }
            }
            setURLParam(UID, config);
            // console.log('UseEffect:');
            // console.log(UID, config, equipment[config]);
        }
    }, [UID]);

    return (
        //config(accessory selection), UID (object UID Selection), equipment(all selected equipment UID), equipmentPrice(Total Price), equipment index(all selected equipment indexes)
        <Context.Provider value={{ config, UID, equipment, equipmentPrice, equipmentIndex, childPage, annotationVisibility:[annotationVisibility, setAnnotationVisibility], annotationSelection, setAnnotationSelection }}>
            <UpdateContext.Provider value={GlobalContextUpdateFunction}>{children}</UpdateContext.Provider>
        </Context.Provider>
    );
}

export default function GlobalContextManager() {
    return <div>GlobalContextManager</div>;
}
