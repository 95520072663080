//Main
import React, { useRef } from "react";
import * as THREE from 'three'
import { useHelper } from "@react-three/drei";

export default function PointLight(props) {
    const light = useRef();
    // console.log('Light')
    // console.log(light)
    // useHelper(light, THREE.PointLightHelper, 0.1, props.color);
    return (
        // <TransformControls depthTest={false} fixed lineWidth={2} mode="translate" object={light}>
        <pointLight
            color={props.color}
            ref={light}
            intensity={props.intensity}
            distance={10}
            position={props.position}
            castShadow = {true}
        />
        // </TransformControls>
    );
}
