import React, { 
    useRef, 
    useState, 
    // useEffect, 
    // Suspense 
} from "react";
// import { useFrame } from "@react-three/fiber";
import { 
    useGLTF, 
    // useBounds, 
    // useAnimations, 
    // useTexture 
} from "@react-three/drei";
import * as THREE from "three";

function Model(props) {
    // const [highResTexture, setHighResTexture] = useState(null);

    // console.log('Model is rerendered')
    // console.log(props)
    const ref = useRef();
    const { scene, nodes, animations, materials } = useGLTF(
        props.Data.URL
    );

    //We will handle changing the models material here. If no texture change is needed then we will skip this. 
    if(!props.Data.variant){
        // var albedo = useTexture("http://dl.dropboxusercontent.com/s/scl/fi/qbsctwkmuqf7gkcxxgc9l/Gun_Metal_Normal_Subtle.png?rlkey=w9ksuvam677g8tj4wki3ijvf8&dl=0");
        
        
        //Issue occurs when a object has two materials. On the second run an issue will pop up saying "Rendered more hooks than during the previous render."
        //Selecting material to switch out texture and output index to know which material is currently being manipulated. 
        Object.keys(materials).forEach((materialName, index) => {
            // console.log(materials[materialName])

            // //We want to check whether we have a texture, value or hexadecimal.
            // //albedo -----------------------------------------------------------------------
            // const albedoURL = props.Data.textures[index].albedo;
            // var albedo = useTexture("http://dl.dropboxusercontent.com/s/scl/fi/qbsctwkmuqf7gkcxxgc9l/Gun_Metal_Normal_Subtle.png?rlkey=w9ksuvam677g8tj4wki3ijvf8&dl=0");
            // const regex = /[0-9A-Fa-f]{6}/g;

            // if (albedoURL.match(regex) ){
            //     //Hexadecimal
            //     console.log ("Albedo hex");

            //     //material.color.set() to set color in 0xffffff format. parseInt('ff0000', 16) to format into hexadecimanl/0xffffff format. 
            //     materials[materialName].color.set(parseInt(props.Data.textures[index].albedo, 16));
            //     materials[materialName].map = null;


            // }else{
            //     //URL
            //     console.log("Albedo UseTexture:");
            //     albedo = useTexture(albedoURL);
            //     console.log(albedo)

            //     // const albedo = useTexture(inputString ?? "/placeholder.jpg");
            //     materials[materialName].map = albedo;
            // }

            // //specular -----------------------------------------------------------------------
            // const specularURL = props.Data.textures[index].specular
            // if(typeof specularURL === 'number'){
            //     //value
            //     console.log('Specular Value')
            //     materials[materialName].specularIntensity = props.Data.textures[index].specular
            // }else{
            //     //URL
            //     console.log("Specular URL");
            // }

            // //roughness -----------------------------------------------------------------------
            // const roughnessURL = props.Data.textures[index].roughness
            // var roughness = useTexture("http://dl.dropboxusercontent.com/s/scl/fi/qbsctwkmuqf7gkcxxgc9l/Gun_Metal_Normal_Subtle.png?rlkey=w9ksuvam677g8tj4wki3ijvf8&dl=0");
            // if(typeof roughnessURL === 'number'){
            //     //value
            //     console.log('Roughness Value')
            //     materials[materialName].roughness = props.Data.textures[index].roughness
            //     materials[materialName].roughnessMap = null
            // }else{
            //     //URL
            //     console.log("Roughness UseTexture:");
            //     roughness = useTexture(roughnessURL);
            //     console.log(roughness)

            //     materials[materialName].roughnessMap = roughness
            //     materials[materialName].roughness = 1
            // }

            // //Metalness -----------------------------------------------------------------------
            // const MetalnessURL = props.Data.textures[index].metalness
            // var metalness =  useTexture("http://dl.dropboxusercontent.com/s/scl/fi/qbsctwkmuqf7gkcxxgc9l/Gun_Metal_Normal_Subtle.png?rlkey=w9ksuvam677g8tj4wki3ijvf8&dl=0");
            // if(typeof MetalnessURL === 'number'){
            //     //value
            //     console.log('Metalness Value')
            //     materials[materialName].metalness = props.Data.textures[index].metalness
            //     materials[materialName].metalnessMap = null
            // }else{
            //     //URL
            //     console.log("Metalness UseTexture:");
            //     metalness =  useTexture(MetalnessURL);
            //     console.log(metalness)

            //     materials[materialName].metalnessMap = metalness
            //     materials[materialName].metalness = 1
            // }

            //normal -----------------------------------------------------------------------

        });

        // scene.traverse((child) => {
        //     if (child.isMesh && child.material.map) {
        //         console.log(child.material)
        //       const texture = child.material.map;
        //       texture.generateMipmaps = true;
        //       texture.minFilter = THREE.LinearMipmapLinearFilter;
        //       texture.anisotropy = 16;
        //       texture.needsUpdate = true;
        //     }
        //   });
    }

    // Automatically generate mipmaps for textures
    scene.traverse((child) => {
        if (child.isMesh && child.material.map) {
          const texture = child.material.map;
          texture.generateMipmaps = true;
          texture.minFilter = THREE.LinearMipmapLinearFilter;
          texture.anisotropy = 16;
          texture.needsUpdate = true;
        }
      });

    scene.castShadow = true//Turn on casting and receiveing shadow
    scene.receiveShadow = true//Turn on casting and receiveing shadow
    scene.visible = true //scene visible to true otherwise it may hide previously selected models. 



    // // Load the high-resolution texture asynchronously
    // if (!highResTexture) {
    //     new THREE.TextureLoader().load(
    //     '/path/to/your/high_res_texture.jpg',
    //     texture => {
    //         // Once the high-resolution texture is loaded, update the state
    //         setHighResTexture(texture);
    //     }
    //     );
    // }

    // // Replace the material's map with the high-resolution texture
    // if (highResTexture) {
    //     materials.YourMaterialName.map = highResTexture;
    //     materials.YourMaterialName.needsUpdate = true;
    // }


    
    // Function to progressively load textures
    // const loadTexturesProgressively = () => {
        // scene.traverse((child) => {
        //     console.log(child)
        // if (child.isMesh && child.material.metalnessMap) {
        //     console.log('we in here')
        //     child.material.metalnessMap.minFilter = THREE.LinearFilter;
        //     child.material.metalnessMap.magFilter = THREE.LinearFilter;
        //     child.material.metalnessMap.generateMipmaps = false;

        //     // Set up a callback for texture loading progress
        //     child.material.metalnessMap.onProgress = (event) => {
        //     const percentLoaded = event.loaded / event.total;
        //     child.material.opacity = percentLoaded; // Adjust opacity based on progress
        //     child.material.needsUpdate = true;
        //     console.log(percentLoaded);
        //     };

        //     // Set up a callback for when the texture finishes loading
        //     child.material.metalnessMap.onLoad = () => {
        //     child.material.opacity = 1; // Reset opacity
        //     child.material.transparent = false;
        //     child.material.needsUpdate = true;
        //     console.log('loaded');
        //     };
        // }else{
        //     console.log('we not in here')
        // }
        // });
    // };


    return (
        <primitive
            ref={ref}
            object={scene}
            scale={1}
            position={[0, 0, 0]} 
        >
            {/* <meshBasicMaterial color={"#000000"} opacity={1} /> */}
        </primitive>
    );
}

export default Model;
