//Main
import React from "react";
import {
    Bloom,
    DepthOfField,
    EffectComposer,
    Noise,
    Vignette,
    Outline,
    Autofocus,
    DotScreen,
    LensFlare,
    SSAO,
    Pixelation,
    BrightnessContrast
} from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";

export default function PostProcessing() {
    return (
        <EffectComposer>
            {/* <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} /> */}
            {/* <Bloom luminanceThreshold={0} luminanceSmoothing={20} height={1200} /> */}
            {/* <Noise opacity={0.03} />  */}
            {/* <Outline  edgeStrength={1} /> */}
            {/* <Autofocus /> */}
            <Vignette eskil={false} offset={0.05} darkness={1.1} />
            <Noise
            premultiply // enables or disables noise premultiplication
            // blendFunction={BlendFunction.ADD} // blend mode
        />
            {/* <SSAO
                blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
                samples={31}
                radius={5}
                intensity={50}
                fade={0.5}
                rings={4} // amount of rings in the occlusion sampling pattern
                distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
                distanceFalloff={0.0} // distance falloff. min: 0, max: 1
                rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
                rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
                luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
                scale={0.5} // scale of the ambient occlusion
                bias={0.5} // occlusion bias
            /> */}
            {/* <BrightnessContrast
                brightness={0.05} // brightness. min: -1, max: 1
                contrast={0.1} // contrast: min -1, max: 1
            /> */}
            {/* <Pixelation granularity={20} /> */}
        </EffectComposer>
    );
}
