//Main
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

//Mui
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Box, Button, Grid, Collapse,Fade, FormGroup, List, ListItemText, Typography, IconButton } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

//Additional Files
import Logo from "../assets/images/general/taurusLogo.svg";
import GetSearchParams from "./GetSearchParams";
// import Data from "../API/Api_Accessories_GX4.json";
import { useGlobalContext, useGlobalContextUpdate } from "./GlobalContextManager";

export default function OverviewSubContent(props) {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });
    const StyledBox = styled(Box)(({ theme }) => ({
        width: "100%",
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "#ffffff10" : grey[800],
    }));
    
    const { accessory, UID, equipmentUID, equipmentPrice, childPage } = useGlobalContext();

    let Data = null;
    if(childPage == 'GX4'){
        Data = require("../API/Api_Accessories_GX4.json")
    }else{
        Data = require("../API/Api_Accessories_GX4XL.json")
    }


    const startFade = 500;
    const endFade = 500;

    const URLUpdate = useGlobalContextUpdate();

    const arrayLength = 7;
    const array = Array.from({ length: arrayLength }, (_, index) => GetSearchParams(index));
    const [config, setConfig] = useState(null);
    const [equipment, setEquipment] = useState(array); //equipment current selection

    const modifiedNumbers = equipment.map((number) => {
        // Convert the number to a string to work with individual digits
        const numberStr = number.toString();
        // Remove the first character (digit)
        const modifiedStr = numberStr.substring(1);
        // Remove any zeros in the modified string
        const cleanedStr = modifiedStr.replace(/0/g, "");
        // Convert the cleaned string back to a number
        const cleanedNumber = parseInt(cleanedStr, 10);
        if (isNaN(cleanedNumber)) {
            return 0;
        } else {
            return cleanedNumber;
        }
    });

    //AccessoryID(int), UID(int), ObjectID(int)
    function EquipAccessory(accessoryTab, UID, objID) {
        // Create a copy of the array
        const updatedEquipment = [...equipment];

        // Modify the desired index
        updatedEquipment[accessoryTab] = UID - objID;

        // console.log(updatedEquipment);

        //Set Equipment List
        setEquipment(updatedEquipment);

        // Update the GlobalContextManager with current selection (function InternalGlobalContextFunction(UID, accessory, objID))
        URLUpdate(UID - objID, accessoryTab, objID);
    }

    return (
        <>
            <StyledBox className="leftDrawerSubDiv">
                <Box className="selScrnLogo overviewLogo" component="img" alt="Taurus Logo" src={Logo} />
                {isDesktopOrLaptop ? (
                    <div className="configTxt desktopDrawerConfig">
                        <Typography className="configTxtsub" variant="h1">
                            Configuration Summary
                        </Typography>
                        <IconButton aria-label="delete" disableRipple onClick={() => props.DrawerChange()}>
                            <ControlPointOutlinedIcon sx={{ transform: "rotate(-0.125turn)" }} color="primary" />
                        </IconButton>
                    </div>
                ) : (
                    <div className="configTxt mobileDrawerConfig">
                        <Typography className="configTxtsub" variant="h1">
                            Configuration Summary
                        </Typography>

                        <IconButton
                            className="mobileDrawerCloseBtn"
                            aria-label="delete"
                            disableRipple
                            onClick={() => props.DrawerChange()}
                        >
                            <ControlPointOutlinedIcon sx={{ transform: "rotate(-0.125turn)" }} color="primary" />
                        </IconButton>
                    </div>
                )}
                <li className="configSummItems">
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <List className="drawerLists" component="div" disablePadding>
                            <FormGroup sx={{ pl: 0 }}>
                                <Box
                                    component="span"
                                    className="CustomConfigBoxes CustomConfigBoxesSel"
                                    sx={{ flexgrow: 1 }}
                                >
                                    <Grid container alignItems="center">
                                        <Grid item xs={3} className="drawerImgGrid">
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: "100%",
                                                    height: "100%",
                                                    // borderRight: "solid 1px #ffffff10",
                                                }}
                                                // alt="The house from the offer."
                                                src="http://dl.dropboxusercontent.com/s/scl/fi/it1qxozh1sis3l9om9zos/Taurus_Base.png?rlkey=a9l6m59dwxmugc3mlwk30e706&dl=0"
                                            />
                                        </Grid>
                                        <Grid item xs={8} sx={{ pl: 2 }}>
                                            <ListItemText primary="1-GX4XL941" />
                                            <ListItemText primary="Taurus GX4 Base" />
                                            <ListItemText primary="$449.99" />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {equipment.map((data, id) => {
                                    // console.log(data, id);
                                    // console.log(Data.Accessories[id].Objects[modifiedNumbers[id]])
                                    return (
                                                <>
                                                {id == 5 && equipment[6]!=700000 ? null :

                                                    <Fade  in={ true }  timeout={{
                                                        enter: startFade,
                                                        exit: endFade
                                                        }}>
                                                        <Box
                                                            key={id}
                                                            component="span"
                                                            className="CustomConfigBoxes CustomConfigBoxesSel"
                                                            sx={{ flexgrow: 1 }}
                                                        >
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={3} className="drawerImgGrid">
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            // borderRight: "solid 1px #ffffff10",
                                                                        }}
                                                                        // alt="The house from the offer."
                                                                        src={Data.Accessories[id].Objects[modifiedNumbers[id]].URL}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={7} sx={{ pl: 2 }}>
                                                                    <ListItemText
                                                                        primary={Data.Accessories[id].Objects[modifiedNumbers[id]].SKU}
                                                                    />
                                                                    <ListItemText
                                                                        primary={Data.Accessories[id].Objects[modifiedNumbers[id]].ProductName}
                                                                    />
                                                                    <ListItemText
                                                                        primary={"$" + Data.Accessories[id].Objects[modifiedNumbers[id]].Price}
                                                                    />
                                                                </Grid>
                                                                {modifiedNumbers[id] != 0 && (
                                                                    <Grid item xs={2} sx={{ pr: 2 }}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setConfig(id);
                                                                                EquipAccessory(
                                                                                    id,
                                                                                    Data.Accessories[id].Objects[modifiedNumbers[id]]
                                                                                        .UID,
                                                                                    modifiedNumbers[id],
                                                                                );
                                                                            }}
                                                                            endIcon={<DeleteForeverIcon color="primary" />}
                                                                        ></Button>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Box>
                                                    </Fade>
                                                }
                                                </>
                                    );
                                })}
                            </FormGroup>
                        </List>
                    </Collapse>
                </li>
                <Box className="configPriceDiv">
                    <Grid
                        // sx={{height: "100%"}}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className="configPriceGridDiv"
                    >
                        <Grid container alignItems="stretch" item xs={12}>
                            <Grid item xs={6} display="flex" justify="left" alignItems="center">
                                <Typography color="success.main" variant="body2">
                                    ORDER NOW:
                                </Typography>
                            </Grid>

                            <Grid item xs={6} display="flex" justifyContent="right" alignItems="center">
                                <Fade  in={ true }  timeout={{
                                    enter: startFade,
                                    exit: endFade
                                }}>
                                    <Typography color="success.main" variant="h2">
                                        ${equipmentPrice}
                                    </Typography>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </StyledBox>
        </>
    );
}
