import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      mxs: 200,
      msm: 300,
      xs: 600,
      sm: 900,
      md: 1200,
      lg: 1536,
      xl: 1836
    }
  }
});
const theme = createTheme({
  typography: {    
    annotation:{
        [defaultTheme.breakpoints.only("mxs")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("msm")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("xs")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("sm")]: {
          fontSize: "0.625rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("md")]: {
          fontSize: "0.65rem"
        },
        [defaultTheme.breakpoints.only("lg")]: {
          fontSize: "0.875rem"
        },
          fontFamily: "Lufga",
          fontWeight: 400,
          fontSize: 12,
          color: '#fff',
    },
    errorTitle:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "4rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "4rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "4rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "4.25rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "7.75rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "6rem"
      },
          fontFamily: "Lufga",
          fontWeight: 500,
          fontSize: 96,
          color: '#fff',
    },
    body1:{
        [defaultTheme.breakpoints.only("mxs")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("msm")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("xs")]: {
          fontSize: "0.75rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("sm")]: {
          fontSize: "0.5rem",
          lineHeight: "135%"
        },
        [defaultTheme.breakpoints.only("md")]: {
          fontSize: "0.525rem"
        },
        [defaultTheme.breakpoints.only("lg")]: {
          fontSize: "0.75rem"
        },
          fontFamily: "Lufga",
          fontWeight: 400,
          fontSize: 12,
          color: '#fff',
    },
    body2:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "0.875rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "0.875rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "0.825rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "0.85rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "0.8625rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "0.875rem"
      },
          fontFamily: "Lufga",
          fontWeight: 500,
          fontSize: 14,
          color: '#fff',
    },
    subtitle1:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "1rem",
        lineHeight: "24.5px"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "1rem",
        lineHeight: "24.5px"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "0.85rem",
        lineHeight: "24.5px"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "0.9rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "0.95rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "1rem"
      },
          fontFamily: "Lufga",
          fontWeight: 300,
          fontSize: 16,
          color: '#fff',
    },
    subtitle2:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "0.5875rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "0.5875rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "0.5875rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "0.6rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "0.6125rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "0.625rem"
      },
          fontFamily: "Lufga",
          fontWeight: 500,
          fontSize: 10,
          color: '#fff',
    },
    h1:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "0.75rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "1.25rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "1.5rem"
      },
          fontFamily: "Lufga",
          fontWeight: 400,
          fontSize: 24,
          color: '#fff',
    },
    h2:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "1.5rem"
      },
          fontFamily: "Lufga",
          fontWeight: 300,
          fontSize: 24,
          color: '#fff',
    },
    h3:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "1rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "1.25rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "1.5rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "1.75rem"
      },
          fontFamily: "Lufga",
          fontWeight: 200,
          fontSize: 28,
          color: '#fff',
    },
    h6:{
      [defaultTheme.breakpoints.only("mxs")]: {
        fontSize: "2rem"
      },
      [defaultTheme.breakpoints.only("msm")]: {
        fontSize: "2rem"
      },
      [defaultTheme.breakpoints.only("xs")]: {
        fontSize: "2rem"
      },
      [defaultTheme.breakpoints.only("sm")]: {
        fontSize: "2.25rem"
      },
      [defaultTheme.breakpoints.only("md")]: {
        fontSize: "2.75rem"
      },
      [defaultTheme.breakpoints.only("lg")]: {
        fontSize: "3rem"
      },
          fontFamily: "Lufga",
          fontWeight: 700,
          fontSize: 48,
          color: '#fff',
    },
  },
  palette: {
    mode:"dark",
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#000'
    },
    success:{
      main: '#BF632D'
    },
    info:{
      main: '#46c6e3'
    },
    action: {
      disabled: "#6A7278"
    },
    text: {
      primary: "#fff"
    }
  }
})

export default theme