import React from 'react'
import { Typography } from '@mui/material'
import { useGlobalContext, useGlobalContextUpdate } from "./GlobalContextManager";



export default function MobileAnnotationHandler(props) {
    const { annotationVisibility, annotationSelection } = useGlobalContext();
    // console.log("annotationSelection " + annotationSelection)
    const annotationData = [
        {
            label_id: "label-1",
            labelData: {
                title: "Graphene Technology",
                description:
                    "The GX4 Graphene uses this unique technology to improve durability of both the frame and the finish, while decreasing the need for cleaning and lubrication. Available with 11 and 13-round magazines, the GX4 Graphene continues to show why the Taurus GX4 is the industry leader in micro-compact pistols.",
            },
        },
        {
            label_id: "label-2",
            labelData: {
                title: "Supreme Accuracy",
                description:
                    "The barrel is the heart of any firearm, and our Taurus GX4 and GX4XL barrels are crafted with exceptional care and expertise. They offer supreme accuracy, durability, and adaptability, setting these firearms apart as reliable tools for any shooter.",
            },
        },
        {
            label_id: "label-3",
            labelData: {
                title: "Streamlined Compactness & Extended Versatility",
                description:
                    "The Taurus GX4 features a slide design that embodies compactness and concealability. For those who seek a bit more versatility without compromising on concealment, the Taurus GX4XL steps up to the plate. Its extended slide provides a longer sight radius, enhancing your ability to acquire targets quickly and accurately. ",
            },
        },
        {
            label_id: "label-4",
            labelData: {
                title: "Adjustable Sights",
                description:
                    "Achieve pinpoint accuracy with adjustable sights that allow you to fine-tune your point of impact. Whether you're hitting targets near or far, these sights give you the control you need.",
            },
        },
    ];
  return (
    <>
    {props.annotationVisibility &&
        (
            annotationSelection != null &&
                <div id="label" className="mobileContent" style={{visibility: "visible"}}>
                    <Typography className="title" variant="h1">
                        {annotationData[annotationSelection].labelData.title}
                    </Typography>
                    <Typography variant="body1">{annotationData[annotationSelection].labelData.description}</Typography>
                </div>

        )
    }
    </>
  )
}

