import React from "react";

//MUI/Theme
import {
  Typography,
  Box,
  Button,
} from '@mui/material';
import theme from "../theme.js";
import { ThemeProvider } from "@mui/material/styles";

//Additional Files
import "./ErrorPage.css"
import Logo from '../assets/images/general/taurusLogo.svg'

export default function ErrorPage() {
    return (
        <ThemeProvider theme={theme}>
                <Box
                    className="ErrorLogo"
                    component="img"
                    sx={{
                        height: "auto",
                        width: "auto",
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                        paddingBottom: 2,
                    }}
                    alt="Taurus Logo"
                    src={Logo}
                />
            <div className="ErrorDiv">
                <Typography variant="errorTitle" color="primary.main">
                    OOPS!
                </Typography>
                <Typography className="ErrorDescription" variant="body2" color="primary.main">
                    The page you are looking for does not exist. Click the button below to return back home.
                </Typography>
                <Button className="ErrorBtn" onClick={()=> window.location.href = "/"} >
                    <Typography variant="body2">Home</Typography>
                </Button>
            </div>
        </ThemeProvider>
    );
}
