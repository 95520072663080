//MUI
import {
    Typography,
    Box,
} from '@mui/material';

//Additional Files
import Logo from '../assets/images/general/taurusLogo.svg'
import SelectionOptions from './SelectionOptions';

//Theme
import theme from '../theme.js'
import { ThemeProvider } from '@mui/material/styles';

export default function SelectionScreen(props) {

    return (
        <ThemeProvider theme={theme}>
        <div className='selectionScreen'>
            <Box className='selScrnLogo'
                component="img"
                sx={{
                    height: 'auto',
                    width: 'auto',
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    paddingLeft: 0
                }}
                alt="Taurus Logo"
                src={Logo}
            />
            <div className='selScrnTxt'>
                <Typography variant='h6' >Choose your model</Typography>
                <Typography variant='body1' color='success.main' >Models can also be changed within the configurator later</Typography>
            </div>
            <SelectionOptions renderSelScrn={(bool) => { props.renderSelScrn(bool) }} componentPage="Main" />
        </div>
        </ThemeProvider>
    )
}