//Main
import * as React from "react";
import { useMediaQuery } from "react-responsive";

//MUI
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SwipeableDrawer, Button, Drawer } from "@mui/material/";

//Theme
import theme from "../theme.js";

//Additional Files
import OverviewContent from "./OverviewContent";

export default function Overview(props) {
    // console.log("OverView Rendered");
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 830px)" });

    const [openLeft, setOpenLeft] = React.useState(false);

    const toggleDrawerLeft = (newOpen) => (event) => {
        setOpenLeft(newOpen);
        //event.stopPropagation is used to stop bubbling from happenning otherwise when a child button is clicked it would also fire off the parent button
        event.stopPropagation();
    };

    function toggleDrawerLeftFunc(newOpen) {
        setOpenLeft(newOpen);
    }
    const StyledBox = styled(Box)(({ theme }) => ({
        width: "100%",
        height: "100vh",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
    }));

    return (
        <>
            {/* LEFT DRAWER */}
            {/* We can switch to Swipable drawer for Mobile */}
            {isDesktopOrLaptop ? (
                <Drawer
                    anchor="left"
                    open={openLeft}
                    onClose={toggleDrawerLeft(false)}
                    sx={{ display: `${props.Visibility ? "intial" : "none"}` }}
                    // disableSwipeToOpen={false}
                    variant="persistent"
                    ModalProps={{
                        //TODO on keep mounted, when the screen is resized, the drawer is visible and is extended. (unwanted behavior)
                        keepMounted: true,
                    }}
                >
                    <StyledBox
                        onClick={toggleDrawerLeft(true)}
                        sx={{ borderRight: `${openLeft ? "none" : "solid #5C6D70 1px"}` }}
                        className={openLeft ? "leftDrawerDiv" : "leftDrawerDivClosed"}
                    >
                        {openLeft ? <div className="leftDrawerLine"></div> : null}
                        {openLeft ? (
                            <div>
                                {isDesktopOrLaptop && (
                                    <div className="pullerLeft" sx={{ zIndex: "999" }} />//onClick={toggleDrawerLeft(false)} />
                                )}

                                <div className="CloseArrowBtnDivAreaL" onClick={toggleDrawerLeft(false)}/>
                            </div>
                        ) : null}
                        <Typography className="leftDrawerTxt" variant="subtitle2" sx={{ color: "primary.main" }}>
                            {openLeft ? null : "OVERVIEW"}
                        </Typography>
                        {openLeft && (
                            <OverviewContent
                                isDesktopOrLaptop={isDesktopOrLaptop}
                                ToggleLeftDrawer={(value) => {
                                    toggleDrawerLeftFunc(value);
                                }}
                            />
                        )}
                    </StyledBox>
                </Drawer>
            ) : (
                <>
                    {!openLeft && (
                        <Button
                            onClick={toggleDrawerLeft(true)}
                            sx={{
                                position: "absolute",
                                bottom: "135px",
                                width: "100vw",
                                height: "50px",
                                zIndex: 99,
                                borderTop: "solid 1px #5C6D70",
                                background: "rgba(255, 255, 255, 0.05)",
                                backdropFilter: "blur(5px)",
                                display: `${props.Visibility ? "intial" : "none"}`,
                            }}
                        >
                            <Typography variant="subtitle2" sx={{ color: "primary.main" }}>
                                {" "}
                                OVERVIEW
                            </Typography>
                        </Button>
                    )}
                    <Drawer
                        anchor="bottom"
                        open={openLeft}
                        onClose={toggleDrawerLeft(false)}
                        ModalProps={{
                            keepMounted: false,
                        }}
                        className={openLeft ? "leftDrawerDiv" : "leftDrawerDivClosed"}
                    >
                        <OverviewContent
                            isDesktopOrLaptop={isDesktopOrLaptop}
                            ToggleLeftDrawer={(value) => {
                                toggleDrawerLeftFunc(value);
                            }}
                        />
                    </Drawer>
                </>
            )}
        </>
    );
}
