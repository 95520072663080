import React, {useRef} from 'react'
import { useFrame,  } from '@react-three/fiber'
import {
    Environment,
} from "@react-three/drei";

export default function EnvironmentHandler() {
    const ref = useRef();

    // useFrame(
    //     (_) => (ref.current.rotation.x  += 0.1)
    // )
  return (
    <group ref={ref}>
        {/* <pointLight position={[-10, -10, -10]} color="red" intensity={3} />
        <pointLight position={[10, -10, 10]} color="blue" intensity={3} /> */}
        <Environment
            background={false}
            blur={0}
            files='https://d1g8zxurdjwyck.cloudfront.net/HDR/studio_small_01_1k.hdr'
        >

            {/* // files="https://dl.dropboxusercontent.com/scl/fi/3uoatki57cr40itfsa18i/studio_small_01_1k.hdr?rlkey=d0rn54i3n3oppt47dljkffdb0&dl=0"
            // files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/kiara/kiara_1_dawn_1k.hdr' */}
            {/* <Environment background={true} blur={0} files='https://dl.dropboxusercontent.com/scl/fi/e375xa2em2qvs1vgt23hq/old_bus_depot_1k.hdr?rlkey=q137tmpmfc4qolp0cz056ctd0&dl=0'> */}
            {/* <Environment> */}
            {/* <LightFormer /> */}
        </Environment>
        {/* <Environment frames={Infinity} resolution={256} background={false} blur={1}> */}
        {/* <Environment background={false} blur={1} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/empty-wharehouse/empty_warehouse_01_1k.hdr'> */}
        {/* <Environment background={false} blur={1} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/studio-small-3/studio_small_03_1k.hdr'> */}
        {/* <Environment
            background={true}
            blur={1}
            files="https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/st-fagans/st_fagans_interior_1k.hdr"
        > */}
        {/* <Environment background={true} blur={0} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/dikhololo/dikhololo_night_1k.hdr'> */}
        {/* <Environment background={true} blur={0} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/forrest-slope/forest_slope_1k.hdr'> */}
        {/* <Environment background={true} blur={0} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/the-sky-is-on-fire/the_sky_is_on_fire_1k.hdr'> */}
        {/* <Environment background={true} blur={0} files='https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/hdris/kiara/kiara_1_dawn_1k.hdr'> */}

    </group>
  )
}
