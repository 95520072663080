//Main
import React from "react";
import { 
    Html, 
    useProgress, 
} from '@react-three/drei'

//MUI/Theme
import {
    Box,
    Typography
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import theme from "../../theme.js";
import { ThemeProvider } from "@mui/material/styles";

const Loader = () => {
    const { progress } = useProgress();
    return (
        // TODO: Change position to match Orbit Control Look at.
        <Html center position={[0, -1, 0]}>
            <ThemeProvider theme={theme}>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress color="success" size={90} value={progress}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="caption" component="div" color="success.main">
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
            </ThemeProvider>
        </Html>
    );
};

export default Loader;

