//Main
import React, { useState } from "react";

//Mui
import { Box, Button, IconButton, Grid, Typography, Drawer, SwipeableDrawer, Fade } from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";

//Additional Files
import OverviewSubContent from "./OvervewSubContent";
import Logo from "../assets/images/general/taurusLogo.svg";
import { useGlobalContext, useGlobalContextUpdate } from "./GlobalContextManager";
// import Data from "../API/Api_Accessories_GX4.json";

export default function OverviewContent(props) {
    const [state, setState] = useState(false);
    const { equipmentPrice, equipmentIndex, childPage } = useGlobalContext();

    let Data = null;
    if(childPage == 'GX4'){
        Data = require("../API/Api_Accessories_GX4.json")
    }else{
        Data = require("../API/Api_Accessories_GX4XL.json")
    }

    const startFade = 500;
    const endFade = 500;

    const toggleDrawer = (open) => (event) => {
        // console.log(open);
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState(open);
    };

    function toggleDrawerBtn() {
        setState(false);
    }

    return (
        <>
            <Box className="selScrnLogo overviewLogo" component="img" alt="Taurus Logo" src={Logo} />

            {!props.isDesktopOrLaptop && (
                <div className="mobileDrawerCloseLeft">
                    <Typography variant="body1" sx={{ fontStyle: 'italic' }}>OVERVIEW</Typography>
                    <IconButton className="mobileDrawerCloseBtn" disableRipple onClick={() => props.ToggleLeftDrawer(false)}>
                        <ControlPointOutlinedIcon sx={{ transform: "rotate(-0.125turn)" }} color="primary" />
                    </IconButton>
                </div>
            )}

            <div className="overviewDescription">
                <Typography
                    variant="body1"
                    className="overviewDescriptionTxt"
                    sx={{
                        color: "primary.main",
                    }}
                >
                    With the introduction of our first-ever micro-compact 9mm, the all-new Taurus GX4™ boldly
                    outclasses anything else in its class—reaching unprecedented new heights in concealed carry firearm
                    engineering, ergonomics and innovation.
                    <br />
                    <br />
                    On the outside, there can be no doubt that every streamlined inch of this rugged striker-fired
                    semi-auto was meticulously crafted for EDC readiness and reliability.
                    <br />
                    <br />
                    While a game-altering combination of full-sized capacity, accuracy and power resides within its
                    ultra-concealable one-inch-wide reinforced frame. The exceptionally affordable Taurus GX4™ is
                    backed by our Limited Lifetime Warranty and industry leading customer service for added peace of
                    mind when it matters the most.
                </Typography>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    className="overviewDescriptionGrid"
                >
                    <Grid container justifyContent="left" alignItems="stretch">
                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Item Number
                            </Typography>
                            <Fade  in={ true }  timeout={{
                                enter: startFade,
                                exit: endFade
                            }}>
                                <Typography variant="subtitle1">1-GX4XL941</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                UPC
                            </Typography>

                            <Fade  in={ true }  timeout={{
                                    enter: startFade,
                                    exit: endFade
                            }}>
                                <Typography variant="subtitle1">7-25327-93811-8</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Caliber
                            </Typography>

                            <Fade  in={ true }  timeout={{
                                    enter: startFade,
                                    exit: endFade
                            }}>
                                <Typography variant="subtitle1">{Data.Accessories[1].Objects[equipmentIndex[1]].Caliber}</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Capacity
                            </Typography>

                            <Fade  in={ true }  timeout={{
                                enter: startFade,
                                exit: endFade
                            }}>
                                <Typography variant="subtitle1">{Data.Accessories[4].Objects[equipmentIndex[4]].Capacity}</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Front Sight
                            </Typography>
                            <Fade  in={ true }  timeout={{
                                enter: startFade,
                                exit: endFade
                            }}>
                                <Typography variant="subtitle1">{Data.Accessories[2].Objects[equipmentIndex[2]].FrontSight}</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={6} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Rear Sight
                            </Typography>

                            <Fade  in={ true }  timeout={{
                                enter: startFade,
                                exit: endFade
                            }}>
                                <Typography variant="subtitle1">{Data.Accessories[2].Objects[equipmentIndex[2]].RearSight}</Typography>
                            </Fade>
                        </Grid>

                        <Grid item xs={12} sx={{ pb: 1 }} justifyContent="left" alignItems="left">
                            <Typography sx={{ fontStyle: "italic" }} variant="body2">
                                Action Type
                            </Typography>
                            
                            <Fade  in={ true }  timeout={{
                                enter: startFade,
                                exit: endFade
                            }}>
                                <Typography variant="subtitle1">SAO</Typography>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div className="overviewPricing ">
                <div className="overviewPriceDiv">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className="overviewPriceGridDiv"
                    >
                        <Grid container alignItems="stretch" item xs={12}>
                            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                                <Typography variant="body2">MSRP:</Typography>
                            </Grid>

                            <Grid item xs={6} display="flex" justifyContent="right" alignItems="center">
                                <Fade  in={ true }  timeout={{
                                    enter: startFade,
                                    exit: endFade
                                }}>
                                    <Typography variant="h2">$449.99</Typography>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className="overviewPriceDiv">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className="overviewPriceGridDiv"
                    >
                        <Grid container alignItems="stretch" item xs={12}>
                            <Grid item xs={6} display="flex" justifyContent="left" alignItems="center">
                                <Typography variant="body2">AS CONFIGURED:</Typography>
                            </Grid>

                            <Grid item xs={6} display="flex" justifyContent="right" alignItems="center">
                                <Fade  in={ true }  timeout={{
                                    enter: startFade,
                                    exit: endFade
                                }}>
                                    <Typography variant="h2">${equipmentPrice}</Typography>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Button className="overviewPriceDiv overviewConfigBtn" onClick={toggleDrawer(true)}>
                    <Typography variant="body2">Configuration Summary</Typography>
                </Button>
            </div>

            <Drawer
                anchor="bottom"
                open={state}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: {
                        height: "100%",
                    },
                }}
            >
                <OverviewSubContent
                    DrawerChange={() => {
                        toggleDrawerBtn();
                    }}
                />
            </Drawer>
        </>
    );
}
