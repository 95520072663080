//Main
import React, { useState } from 'react'

//MUI
import {
    Typography
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

//Additional Files
import SelectionOptions from './SelectionOptions';

export default function Footer() {    
    // console.log('Footer Rendered')
    const [footerChange, setFooterChange] = useState(false)

    function FooterChange(value) {
        setFooterChange(value);
    }

    return (
        <>
            <div
                className='footer'
                onMouseEnter={() => FooterChange(true)}
                onMouseLeave={() => FooterChange(false)}
            >
                <div className='footerTextDiv'>
                    <Typography variant="subtitle2" className='footerText'>
                        MODELS
                        {!footerChange && <KeyboardArrowUpIcon />}
                    </Typography>
                </div>
                {footerChange &&
                    <SelectionOptions componentPage="Footer" />
                }
            </div>
        </>
    )
}