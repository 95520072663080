import { useLocation } from "react-router-dom";

export default function GetSearchParams(paramsName) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Access specific query parameter values
    let paramValue = queryParams.get(paramsName); // Change 'paramName' to the actual parameter name you want to access
    
    if(!paramValue){
        // console.log("paramsName"+paramsName)
        // console.log(paramsName+': url param not available');
        paramValue = (paramsName+1) * 100000;

        paramValue=parseInt(paramValue);
        return paramValue;
    }else{
        if(paramValue == paramsName){
            return;
        }

        paramValue=parseInt(paramValue);
        return paramValue;
    }
}
